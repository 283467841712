
#responseTextArea {
    max-width: 450px;
}
#pdfDiv {
    height: 500px;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
}
.popup-wrapper {
    display: none;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
}
.popup {
    text-align: center;
    width: 100%;
    max-width: 300px;
    margin: 120px auto;
    padding: 2px;
    background: #fff;
    position: relative;
}
.popup-close {
    position: absolute;
    top: 105px;
    right: 10px;
    cursor: pointer;
}
.title-table {
    width: 60% !important;
}
.pdf-container {
    position: relative;
}
#input-container-pdfLink, #input-container {
    position: absolute;
    width: 80%;
    bottom: -15px;
    left: 20%;
    display: none;
}
.input-group-append {
    width: 75% !important;
}
